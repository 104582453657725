import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';


import Iceberg from '@/views/Icerberg.vue';
import Projects from "@/views/Projects.vue";
import ProjectView from "@/views/ProjectView.vue";
import Home from "@/views/Home.vue";
import Books from "@/views/Books.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/skills',
    name: 'Skills',
    component: Iceberg,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
  },
  {
    path : "/projects/:slug",
    name : "Project",
    component : ProjectView
  },
  {
    path : "/books",
    name : "Books",
    component : Books
  },
  { path: "*", component: Home }
];


const router = new VueRouter({
  routes, 
  mode: 'history', 
});

export default router;