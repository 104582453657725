























import { Component, Vue } from 'vue-property-decorator';
import {
    PerspectiveCamera, WebGLRenderer,
    Scene as ThreeScene, AmbientLight,
    Mesh, Color, sRGBEncoding, DirectionalLight,
    PlaneGeometry,CubeTextureLoader
    
} from 'three';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Point from "@/objects/Point"
import { Item } from '@/objects/Category';
import Category from "@/objects/Category";
import WaterShader from "@/shaders/WaterShader";
import Raycast from "@/utils/Raycast"
import Cross from "@/svg/Cross.vue"
import Levels, {Level} from "@/objects/Levels"
import Fish from "@/objects/Fish"
import Submarine from "@/objects/Submarine"
import Penguin from "@/objects/Penguin"
@Component({components : {Cross}})
export default class Iceberg extends Vue {

    renderer!: WebGLRenderer
    scene!: ThreeScene
    camera: PerspectiveCamera = new PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 1000);
    points : Point[] = []
    close_info = true
    actual_item : Item = {
        name : "test",
        description : "test",
        img : "test",
    }
    actual_category  : Category = {
        id : 999,
        name : "test",
        items : []
    }
    levels = Levels
    fish : Fish = new Fish()
    actual_level : Level = this.levels[0]
    next_level : Level | null = this.levels[1]
    previous_level : Level | null = null
    show_next_level = true
    show_previous_level = false
    submarine : Submarine = new Submarine()
    close_welcome = false
    penguin : Penguin = new Penguin()
    categories : Category[] = []

    constructor(){
        super()
    }

    async init_scene() {
        const base_url = window.location.origin
        this.categories = (await (await fetch(base_url+ "/portfolio.json")).json() as {skills : {categories: Category[]} }).skills.categories


        this.create_camera();
        this.create_renderer();
        this.create_water();
        this.create_light();
        this.create_iceberg();
        this.create_skybox();
        this.create_question_marks()

        this.animate();

        this.on_click()
        this.on_mouse_move()
        this.on_move_camera()

        await this.penguin.init(this.scene)
        this.penguin.init_info_buble(this.camera)
        this.fish.init(this.scene)
        this.submarine.init(this.scene)

        this.on_window_resize()
        window.addEventListener('resize', this.on_window_resize);

 
  

        
    }

    async mounted() : Promise<void> {
        await this.init_scene();
    }

    create_water() : void{
        const water_shader = WaterShader;
        const water = new Mesh(new PlaneGeometry(1000,40), water_shader)
        water.position.set(0, -20, 11);
        this.scene.add(water);

        //Background water
        const water_shader_2 = water_shader.clone();
        water_shader_2.transparent = false
        const water_2 = water.clone();
        water_2.material = water_shader_2;
        water_2.position.z = -11;
        water_2.material.opacity = 1;
        this.scene.add(water_2);
    }



    create_camera() : void {
        this.camera.position.set(0, 0, 30);
    }

    create_renderer() : void {
        this.scene = new ThreeScene();
        this.renderer = new WebGLRenderer();
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        const app = document.getElementById('iceberg');
        app!.appendChild(this.renderer.domElement);
    }


    create_light() : void {
        const light = new AmbientLight(new Color('hsl(0, 0%, 100%)'));
        this.scene.add(light);
        const dirLight = new DirectionalLight( 0xefefff, 1.5 );
        dirLight.position.set( 10, 10, 10 );
        this.scene.add( dirLight );
    }

    animate(): void {
       requestAnimationFrame(this.animate);
        this.renderer.render(this.scene, this.camera);
        this.fish.animate()
        this.submarine.animate()
    }

    create_skybox(extension = ".jpg") {

        const filename = "./skybox/bluecloud"
        const sides = ["ft", "bk", "up", "dn", "rt", "lf"];
        const images = sides.map(side => {
            return filename + "_" + side + extension;
        });
        const texture = new CubeTextureLoader().load(images)
        this.scene.background = texture;
    }
    

    async create_iceberg(){
        const iceberg = (await new GLTFLoader().loadAsync("./iceberg.glb")).scene;
        iceberg.position.set(-4,-1,0);
        iceberg.scale.set(1,5,3)
        iceberg.rotateY(Math.PI/2)
        this.scene.add(iceberg);
    }

    async create_question_marks() {
        
        for (const level of this.levels) {
            const category = this.categories.find(c => c.name == level.category_name)!;
            for (const item of category.items) {
                const point = new Point(item.name);
                let position : THREE.Vector3 = new THREE.Vector3();
                do {
                    position = new THREE.Vector3(
                        Math.random() * (level.max_x - level.min_x) + level.min_x,
                        Math.random() * (level.max_y - level.min_y) + level.min_y,
                        13
                    );
                } while (this.points.some(p => p.mesh!.position.distanceTo(position) < 0.8));
                await point.init(position, this.scene,this.categories);
                this.points.push(point);
            }
        }
    }

    on_mouse_move(){
        window.addEventListener("mousemove", event => {
            this.check_points_mouse_mouve(event)
            const pinguins = Raycast.get_closest_object(this.camera,event,[this.penguin.object])
            if(pinguins){
                document.body.style.cursor = "pointer"

                const welcome = document.getElementById('welcome');
                if(welcome){
                    welcome!.style.left = (event.pageX+ 10) + 'px';
                    welcome!.style.top = (event.pageY - 190) + 'px';
                }
                this.close_welcome = false
            }

        })
    }

    check_points_mouse_mouve(event : MouseEvent){
        let points_mesh = this.points.flatMap(p => p.mesh!)
            const point_mesh = Raycast.get_closest_object(this.camera,event,points_mesh)
            if(point_mesh){
                const point = this.points.find(p => p.mesh!.uuid == point_mesh.uuid)!;
                point.mesh?.scale.set(1.2,1.2,1.2)
                points_mesh = points_mesh.filter(p => p.uuid != point_mesh.uuid)
                point.on_click();
                this.update_info_div(event,point)
                document.body.style.cursor = "pointer"
            }else{
                this.close_info = true
                document.body.style.cursor = "default"
            }
            points_mesh.forEach(p => p.scale.set(1,1,1))
    }

    on_click() {
        window.addEventListener("click", async (event) => {
            event.preventDefault();
            const points_mesh = this.points.flatMap(p => p.mesh!)
            const point_mesh = Raycast.get_closest_object(this.camera,event,points_mesh)
            if(point_mesh){
                const point = this.points.find(p => p.mesh!.uuid == point_mesh.uuid)!;
                point.on_click();
                this.update_info_div(event,point)
 
            }else{
                this.close_info = true
            }
  
        })
    }

    update_info_div(event : MouseEvent, point : Point){
        this.close_info = false;
        const info_div = document.getElementById('info');
        if(info_div){
            info_div!.style.left = (event.pageX + 10) + 'px';
            info_div!.style.top = (event.pageY + 10) + 'px';
            this.actual_item = point.item!;
            const info_description = document.getElementById('info__description')!;
            //info_description.innerHTML = point.item!.description; //Warning : innerHTML is not secure if user can modify description
            this.actual_category = point.category!;
        }

    }

    on_move_camera() {
        window.addEventListener("keydown", event => {
            const key = event.key;
            if(key == "ArrowUp"){
                this.up_camera()
    
            }else if(key == "ArrowDown"){
                this.down_camera()
            }else if(key == " "){
                this.points.forEach(p => p.on_click())
            }
        })
        window.addEventListener("wheel", event => {
            if(event.deltaY < 0){
                this.up_camera()
            }else{
                this.down_camera()
            }
        })
    }

    up_camera() {
        if(this.can_up_camera){
            this.camera.position.y += 0.5;
            this.submarine.y_target = this.camera.position.y
            this.close_info = true
            this.set_actual_level()
        }
        

    }

    down_camera() {
        if(this.can_down_camera){
            this.camera.position.y -= 0.5;
            this.submarine.y_target = this.camera.position.y
            this.close_info = true
            this.set_actual_level()
        }

    }

    set_actual_level() {
        const closest_level = Math.abs(this.submarine.object.position.y - this.levels[0].min_y)
        this.actual_level = this.levels.reduce((prev, curr) => {
            const curr_diff = Math.abs(this.submarine.object.position.y - curr.min_y)
            return curr_diff < closest_level ? curr : prev
        })
        this.set_previous_and_next_level()
    }

    set_previous_and_next_level(){

        const index = this.levels.indexOf(this.actual_level)
        this.show_next_level = index !== this.levels.length - 1;
        this.next_level = this.show_next_level ? this.levels[index + 1] : null;

        this.show_previous_level = index !== 0;
        this.previous_level = this.show_previous_level ? this.levels[index - 1] : null;
    }

    get can_up_camera() {
        return this.camera.position.y <= -0.5
    }

    get can_down_camera() {
        return this.camera.position.y >= -16
    }


    on_window_resize() {
        const new_width = window.innerWidth;
        const new_height = window.innerHeight;
        
        this.camera.aspect = new_width / new_height;
        
        this.renderer.setSize(new_width, new_height);
        this.camera.fov = new_width < 1000 ? 70 : 40
        if(new_width < 1000){
            this.camera.position.x -= 2
        }
        this.camera.updateProjectionMatrix();
    }

    click_on_eye(){
        this.points.forEach(p => p.on_click())
        // hide eye
        const eye = document.getElementById('eye');
        if(eye){
            eye!.style.visibility = "hidden"
        }

    }




    
}
