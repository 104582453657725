import { Mesh, Object3D, Scene } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default class Fish{

    object : Object3D = new Object3D();
    direction = 1;


    async init(scene : Scene){
        const fish = (await new GLTFLoader().loadAsync("./fish.glb")).scene;
        fish.position.set(-25, -4, -11);
        fish.rotateY(-(Math.PI/2))
        fish.scale.set(10,10,10)
        scene.add(fish);
        this.object = fish;
    }

    animate(){
        if(this.object.position.x >= -23){
            this.direction = -1
            this.object.rotateY(Math.PI) // * 2
        }else if (this.object.position.x <= -27){
            this.direction = 1
            this.object.rotateY(Math.PI)
        }
        this.object.position.x += (0.02 * this.direction)
    }
}