















import Project from '@/objects/Project';
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class Projects extends Vue {

  projects : Project[] = [];

  async mounted(){
    const base_url = window.location.origin
    this.projects = (await (await fetch(base_url + "/portfolio.json")).json() as {projects : Project[]}).projects
    this.projects = this.projects.sort((a,b) => Date.parse(b.date) - Date.parse(a.date))
  }

  go_to_project(project : Project){
    this.$router.push(`/projects/${project.slug}`)
  }
}

