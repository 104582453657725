import { Vector3 } from "three"
import * as THREE from 'three';
import Category, { Item } from "./Category";

export default class Point {

    item_name : string
    item : Item | undefined
    category : Category | undefined
    mesh : THREE.Mesh| undefined


    constructor(item_name : string) {
        this.item_name = item_name
    }

    
    async init(position : Vector3, scene : THREE.Scene, categories : Category[]){
        const geometry = new THREE.CircleGeometry( 0.3, 32 ); 
        this.category = categories.find(category => category.items.find(item => item.name === this.item_name))
        this.item = this.category?.items.find(item => item.name === this.item_name)
        const circle_texture = await (new THREE.TextureLoader().loadAsync("question-2.jpg"));
        const material = new THREE.MeshBasicMaterial( { map: circle_texture } );
        
        material.transparent = true
        this.mesh = new THREE.Mesh( geometry, material ); 
        this.mesh.position.set(position.x, position.y, position.z)
        scene.add(this.mesh );
    }

    async on_click(){
        if(this.item && this.mesh){
            const material = new THREE.MeshBasicMaterial( { map: await (new THREE.TextureLoader().loadAsync(this.item.img)) } );
            material.transparent = true
            //transform to square, keep same scale
            this.mesh.geometry = new THREE.PlaneGeometry( 0.6, 0.6, 1 );
            this.mesh.material = material
        }
    }


}