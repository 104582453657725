















import Book from '@/objects/Book';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Books extends Vue {

  books : Book[] = [];

  async mounted(){
    const base_url = window.location.origin
    this.books = (await (await fetch(base_url + "/portfolio.json")).json() as {books : Book[]}).books
    this.books = this.books.sort((a,b) => a.title.localeCompare(b.title))
  }

}

