





import { Component, Vue } from 'vue-property-decorator';
import Iceberg from '@/views/Icerberg.vue';
@Component({ components: { Iceberg } })
export default class App extends Vue {

}
