
























import { Component, Vue } from 'vue-property-decorator';
import { VueperSlides, VueperSlide } from 'vueperslides';
import Project from '@/objects/Project';
import 'vueperslides/dist/vueperslides.css'
@Component({ components: { VueperSlides, VueperSlide } })
export default class ProjectView extends Vue {

    project = new Project();
    slides_height = "500px"
    slides_width = "1000px"
    visible_slides = 1
    nb_grid_items = 5

    async mounted() {
        this.set_slides_height()
        const base_url = window.location.origin
        const project_slug = this.$route.params.slug
        const projects = await (await fetch(base_url + "/portfolio.json")).json()
        this.project = projects.projects.find((project : Project) => project.slug == project_slug)
        if(this.project.slides.length > 1){
            this.visible_slides = 2
        }
        if(this.project.technos.length < this.nb_grid_items){
            this.nb_grid_items = this.project.technos.length
        }

        //on resize
        window.addEventListener('resize', () => {
            this.set_slides_height()
        })
    }

    set_slides_height(){
        if(window.innerWidth <= 800){
            this.slides_height = "250px"
            this.slides_width = "100%"
            this.nb_grid_items = 3
        }else{
            this.slides_height = "350px"
            this.slides_width = "1000px"
            this.nb_grid_items = 5
        }
    }



    
}
