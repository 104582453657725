export default [
    {
        min_x : -3,
        max_x : 3.5,
        min_y : 1,
        max_y : 2,
        category_name : "Frontend",
    },
    {
        min_x : -6,
        max_x : 4,
        min_y : -2,
        max_y : 0,
        category_name : "Backend",
    },
    {
        min_x : -7,
        max_x : 3.5,
        min_y : -4,
        max_y : -2,
        category_name : "Bases de données",
    },
    {
        min_x : -5.5,
        max_x : 3.5,
        min_y : -6,
        max_y : -4,
        category_name : "Cybersécurité",
    },
    {
        min_x : -5.5,
        max_x : 3.5,
        min_y : -8,
        max_y : -6,
        category_name : "DevOps",
    },
    {
        min_x : -5.5,
        max_x : 3.5,
        min_y : -9,
        max_y : -8,
        category_name : "Réseau",
    },
    {
        min_x : -5.5,
        max_x : 3.5,
        min_y : -10,
        max_y : -9,
        category_name : "Architecture",
    }
] as Level[];

export interface Level {
    min_x : number,
    max_x : number,
    min_y : number,
    max_y : number,
    category_name : string,
}