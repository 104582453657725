export default class Project {
    name = "";
    description = ""; 
    date = "";
    slug = "";
    slides: {
        image?: string;
        video?: string;
    }[] = [];
    technos: {
        name: string;
        image: string;
    }[] = [];
    
}