import { Mesh, Object3D, Scene } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default class Submarine{

    object : Object3D = new Object3D();
    y_target = -1;


    async init(scene : Scene){
        const submarine = (await new GLTFLoader().loadAsync("./submarine.glb")).scene;
        submarine.position.set(20, -1, -11);
        submarine.scale.set(0.5,0.5,0.5)
        submarine.rotateY(-(Math.PI/2))
        scene.add(submarine);
        this.object = submarine;
    }

    animate(){
        const speed = 0.04;
        if(this.y_target <= -1){
            if(this.object.position.y > this.y_target){
                this.object.position.y -= speed
            }else if(this.object.position.y < this.y_target){
                this.object.position.y += speed
            }
        }else{
            this.y_target = -1
        }

    }
}