import { Camera, Mesh, Object3D, Scene } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default class Penguin{

    object : Object3D = new Object3D();
    direction = 1;


    async init(scene : Scene){
        const penguin = (await new GLTFLoader().loadAsync("./penguin.glb")).scene;
        penguin.position.set(-12,0.8,1.5)
        penguin.scale.set(0.2,0.2,0.2)
        penguin.rotateY(Math.PI/2)
        scene.add(penguin);
        this.object = penguin;
    }

    init_info_buble(camera : Camera){
        const objectPosition = this.object.position.clone();
    
        // Utiliser la fonction project() pour obtenir les coordonnées à l'écran
        objectPosition.project(camera);

        // Convertir les coordonnées en coordonnées 2D à l'écran
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const x = (objectPosition.x + 1) * (screenWidth / 2);
        const y = (-objectPosition.y + 1) * (screenHeight / 2);
        const info_div = document.getElementById('welcome');
        if(info_div){
            info_div!.style.left = (x + 10) + 'px';
            info_div!.style.top = (y - 190) + 'px';
        }

        if(screenWidth < 600){
            info_div!.style.left = "30px";
        }
    }
}