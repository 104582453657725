import * as THREE from 'three';

export default class Raycast{

    static get_closest_object(camera : THREE.Camera, event : MouseEvent, objects_selectables : THREE.Object3D[]) : THREE.Object3D|undefined {
        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();
        mouse.x = ( event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight) * 2 + 1;
        raycaster.setFromCamera( mouse, camera );
        const intersects = raycaster.intersectObjects(  objects_selectables ); 
        if ( intersects.length > 0 ) {
            return intersects[0].object;
        }
    }
}