import { ShaderMaterial } from "three";

const vertexShader = `
varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}
`;

const fragmentShader = `
varying vec2 vUv;

void main() {
    vec3 colorStart = vec3(0.0, 0.0, 1.0); // Couleur bleue foncée
    vec3 colorEnd = vec3(0.0, 0.89, 1.0);  // Couleur bleue claire

    gl_FragColor = vec4(mix(colorStart, colorEnd, vUv.y), 1.0);
    gl_FragColor.a = 0.5; // Définissez l'opacité du fragment à 50%
}
`;

export default new ShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    transparent: true, // Activez la transparence du matériau
    depthWrite: true,
});